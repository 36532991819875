import * as React from "react"
import {graphql, navigate} from "gatsby";
import ImageLink from "../components/global/imageLink"
import InnerLayout from "../components/global/innerLayout"
import LargeCarousel from "../components/global/largeCarousel";
import Pagination from "../components/global/pagination"
import parse from "html-react-parser";
import Select from "../components/global/select"
import defaultFrontCover from "../images/coming-soon-front-cover.png"
import './stories.scss'

export const query = graphql`
    query($limit: Int, $offset: Int, $currentCategoryId: [Craft_QueryArgument], $publishedDateFilter: [Craft_QueryArgument]) {
      craft {
        houseAds: globalSet(handle: "freeStoriesHouseAds") {
          ... on Craft_freeStoriesHouseAds_GlobalSet {
            houseAdTop {
              ... on Craft_houseAdTop_ad_BlockType {
                key: id
                image {
                  url(transform: "storiesLeaderboardAd728x90Transform")
                  ... on Craft_websiteHouseAds_Asset {
                    altText
                  }
                }
                imageLink {
                  linkUrl
                  openInNewTab
                }
              }
            }
            houseAdLeft {
              ... on Craft_houseAdLeft_ad_BlockType {
                key: id
                image {
                  url(transform: "storiesSkyscraperAd160x600Transform")
                  ... on Craft_websiteHouseAds_Asset {
                    altText
                  }
                }
                imageLink {
                  linkUrl
                  openInNewTab
                }
              }
            }
            houseAdBottom {
              ... on Craft_houseAdBottom_ad_BlockType {
                key: id
                image {
                  url(transform: "storiesLeaderboardAd728x90Transform")
                  ... on Craft_websiteHouseAds_Asset {
                    altText
                  }
                }
                imageLink {
                  linkUrl
                  openInNewTab
                }
              }
            }
          }
        }
        page: entry(section: "freeStoriesIndex") {
          ... on Craft_freeStoriesIndex_freeStoriesIndex_Entry {
            seomatic {
              metaTitleContainer
              metaTagContainer
              metaLinkContainer
              metaScriptContainer
              metaJsonLdContainer
              metaSiteVarsContainer
              frontendTemplateContainer
            }
            freeStoriesIndexHeroCarousel {
              ... on Craft_freeStoriesIndexHeroCarousel_slide_BlockType {
                image {
                  url(transform: "heroImageTransform")
                  ... on Craft_websitePublishingCarousel_Asset {
                    altText
                  }
                }
                imageLink {
                  linkUrl
                  openInNewTab
                }
              }
            }
            section2TextContentWithLink
            id
          }
        }
        newsletterTopics: categories(group:"newsletterTopic", relatedToEntries: {section: "freeStoryPage"}) {
          id
          title
          value: slug
          slug
        }
        freeStories: entries(section: "freeStoryPage", relatedToCategories: {id: $currentCategoryId}, limit: $limit, offset: $offset, newsletterPublishDate: $publishedDateFilter, orderBy: "newsletterPublishDate desc") {
          ... on Craft_freeStoryPage_default_Entry {
            id
            slug
            title
            description: contentExtendedButtonsImgOptionsFreeStory
            book {
              ... on Craft_bookPage_default_Entry {
                title
                slug
                bookFrontCover {
                  key: id
                  url(transform: "bookCoverLarge")
                  ... on Craft_websiteBookPage_Asset {
                    altText
                  }
                }
              }
            }
          }
        }
      }
    }
`

const getFreeStories = (stories) => {
    if (stories.length > 0) {
        return (
            stories.map(story => (
                <div className="stories-box">
                    <div className="stories-box-left">
                        <ImageLink src={getBookFrontCover(story.book[0])} alt={getBookFrontCoverAltText(story.book[0])} to={`/story/${story.id}/${story.slug}`} openInNewTab={false}></ImageLink>
                    </div>
                    <div className="stories-box-right">
                        <a href={`/story/${story.id}/${story.slug}`}><h4>{story.title}</h4></a>
                        <p>{getStorySnippet(story)}</p>
                        <a href={`/story/${story.id}/${story.slug}`} className="btn btn-primary">Read More</a>
                    </div>
                </div>
            ))
        )
    } else {
        return null
    }
}

const getHouseAd = (node) => {
    if (node.length > 0) {
        return (
            <ImageLink src={node[0].image[0].url} alt={node[0].image[0].altText} to={node[0].imageLink[0].linkUrl} openInNewTab={node[0].imageLink[0].openInNewTab}></ImageLink>
        )
    } else {
        return null
    }
}

const getStorySnippet = (story) => {
    if (story.description) {
        const snippet = story.description.replace(/<[^>]*>?/gm, '').substring(0, 397)

        if (snippet.length < 397) {
            return snippet
        } else {
            return snippet + "..."
        }
    } else {
        return null
    }
}

const getBookFrontCover = (node) => {
    if (node.bookFrontCover.length > 0) {
        return node.bookFrontCover[0].url
    } else {
        return defaultFrontCover
    }
}

const getBookFrontCoverAltText = (node) => {
    if (node.bookFrontCover.length > 0) {
        return node.bookFrontCover[0].altText
    } else {
        return null
    }
}

const getNewsletterTopics = (topics) => {
    if (topics.length > 0) {
        return topics.map(topic => (
            <li><a href={`/stories/${topic.slug}`} className={topic.selected ? "active" : ""}>{topic.title}</a></li>
        ))
    } else {
        return null
    }
}

const changeNewsletterTopic = (event) => {
    navigate(`/stories/${event.target.value}`)
}

const getCurrentNewsletterTopic = (newsletterTopics) => {
    return newsletterTopics.find(topic => topic.selected === true).title
}

const Stories = ({ data, pageContext }) => {
    // Add All option to newsletter topics
    if (!data.craft.newsletterTopics.some(topic => topic.id === null)) {
        data.craft.newsletterTopics.unshift({
            title: "All",
            slug: "",
            value: "",
            id: null
        })
    }

    // Set selected newsetter topic
    data.craft.newsletterTopics.map(topic => {
        if (topic.id === pageContext.currentCategoryId) {
            topic.selected = true
        }
        return topic
    })

    return (
        <InnerLayout seomatic={data.craft.page.seomatic} entryId={data.craft.page.id}>
            <div className="midd">

                <div className="page-title padding-small">
                    <div className="container">
                        <h2>Free Stories</h2>
                    </div>
                </div>

                <div className="stories-page">
                    <div className="container-small">

                        <div className="banner-top">
                            {getHouseAd(data.craft.houseAds.houseAdTop)}
                        </div>

                        <LargeCarousel slides={data.craft.page.freeStoriesIndexHeroCarousel} items={1} loop={true} autoplay={true} autoplayTimeout={5000} autoplayHoverPause={true}></LargeCarousel>

                        <div className="page-description">
                            {parse(data.craft.page.section2TextContentWithLink)}
                        </div>

                        <div className="content-column-row">
                            <div className="sidebar">

                                <div className="left-menu-block">
                                    <h3>NEWSLETTERS</h3>
                                    <Select changeHandler={changeNewsletterTopic} classes="newsletter-select" options={data.craft.newsletterTopics}></Select>

                                    <ul>
                                        {getNewsletterTopics(data.craft.newsletterTopics)}
                                    </ul>
                                </div>
                                <div className="side-banner">
                                    {getHouseAd(data.craft.houseAds.houseAdLeft)}
                                </div>
                            </div>
                            <div className="content-right">
                                <h3>{getCurrentNewsletterTopic(data.craft.newsletterTopics)}</h3>

                                <div className="stories-list">
                                    {getFreeStories(data.craft.freeStories)}
                                </div>

                                <Pagination pageContext={pageContext}/>

                            </div>
                        </div>
                        <div className="bottom-banner">
                            <div className="container">
                                {getHouseAd(data.craft.houseAds.houseAdBottom)}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </InnerLayout>
    )
}

export default Stories
