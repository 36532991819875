import * as React from "react"
import './pagination.scss'

const getIsActivePage = (page, currentPage) => {
    if (page === currentPage) {
        return "active"
    } else {
        return null
    }
}

const getPaginationLinks = (props) => {
    return Array.from({ length: props.pageContext.pageCount }).map((_,i) => {
        if (i === 0) {
            return <li><a href={`${props.pageContext.currentPageRoute}`}
                          className={getIsActivePage(i + 1, props.pageContext.currentPage)}>{i + 1}</a></li>
        } else {
            return <li><a href={`${props.pageContext.currentPageRoute}/${i + 1}`}
                          className={getIsActivePage(i + 1, props.pageContext.currentPage)}>{i + 1}</a></li>
        }
    })
}

const getNextLink = (props) => {
    if (props.pageContext.pageCount > props.pageContext.currentPage) {
        return (
            <li><a href={`${props.pageContext.currentPageRoute}/${props.pageContext.currentPage+1}`}>Next</a> &gt;</li>
        )
    } else {
        return null
    }
}

const getPreviousLink = (props) => {
    if (props.pageContext.currentPage > 1) {
        if (props.pageContext.currentPage === 2) {
            return (
                <li>&lt; <a href={`${props.pageContext.currentPageRoute}`}>Previous</a></li>
            )
        } else {
            return (
                <li>&lt; <a href={`${props.pageContext.currentPageRoute}/${props.pageContext.currentPage-1}`}>Previous</a></li>
            )
        }
    } else {
        return null
    }
}

const Pagination = (props) => {
    if (props.pageContext.pageCount > 1) {
        return (
            <div className="paging">
                <ul>
                    {getPreviousLink(props)}
                    {getPaginationLinks(props)}
                    {getNextLink(props)}
                </ul>
            </div>
        )
    } else {
        return null
    }
}

export default Pagination